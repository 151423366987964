// it need to be visible in order t show the horizontal scrollbar
tree-viewport {
  overflow: visible !important;
}

.p-tree {
  width: 100%;
  padding: 0;

  .tree-children {
    overflow: visible;
    padding-left: 20px;
  }

  .node-wrapper {
    padding: 2px;
    position: relative;

    .toggle-children-placeholder {
      width: 0 !important;
      height: 0 !important;
      padding: 0 !important;
    }

    .page-node-wrapper {
      display: flex;
      width: 100%;
      position: relative;
      font-size: 12px;
      white-space: nowrap;
      word-wrap: normal;
      word-break: keep-all;
      cursor: pointer;

      .page-node {
        height: 20px;
        display: flex;
        align-items: center;

        > i.fa.fa-image {
          font-size: 17px;
          margin-right: 5px;
        }

        > svg {
          max-width: 18px !important;
          max-height: 18px !important;
        }
      }

      > i.fa {
        font-size: 18px;
      }

      input[type="text"] {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 6;
        height: 100% !important;
        width: 100% !important;
        border: none;
      }

      li:not(.sortable-placeholder),
      .dz-preview:not(.sortable-placeholder) {
        display: none !important;
      }

      .icon {
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-right: 5px;
        float: left;
        width: 20px;
        height: 18px;

        img {
          position: absolute;
          max-width: 18px !important;
          max-height: 18px !important;
          border: 1px solid #fff;
        }
      }
    }

    .page-node-wrapper {
      &:hover {
        .commands {
          opacity: 1;
        }
      }
    }
  }

  .node-drop-slot {
    height: 6px;
    transition: 0.1s all linear;
  }

  .node-drop-slot.is-dragging-over {
    height: 27px !important;
    background: #888 !important;
    border: 1px solid #ddd !important;
  }

  .toggle-children {
    background: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    left: 35px;
    top: 2px;
    display: block;

    &:after {
      content: '\25BA';
      font-size: 12px;
    }
  }

  .toggle-children-wrapper-expanded .toggle-children {
    top: 4px;
    left: 30px;
    transform: rotate(90deg);
  }

  .node-content-wrapper,
  .node-wrapper {
    padding: 0 !important;
  }

  .node-content-wrapper.is-dragging-over {
    height: 27px !important;
    background: #888 !important;

    .commands {
      background: #888 !important;
    }
  }

  .node-wrapper {
    min-height: 24px !important;
  }

  .tree-node {
    &.library {
      margin-top: 20px;
    }
  }

  .tree-node-active .node-wrapper > .node-content-wrapper,
  .tree-node-focused .node-wrapper > .node-content-wrapper,
  .tree-node-active.tree-node-focused .node-wrapper > .node-content-wrapper,
  .node-content-wrapper {
    border-radius: 0;
    transition: none;
    box-shadow: none;
    background: none;
    width: 100%;

    &:hover {
      background: none;
      box-shadow: none;
    }
  }

  .tree-node-active > * > .node-wrapper .node-content-wrapper:not(.splash-selected) .page-node-wrapper {
    background: #ddd;
    color: #333;

    .toggle-children {
      color: #333;
    }
  }

  .tree-node-active > * > .node-wrapper .node-content-wrapper.splash-selected .page-node-wrapper {
    background: #9b9b9b;
    color: #333;
  }

  .tree-node-focused > * > .node-wrapper {
    background: none;

    .commands {
      background: none;
    }
  }

  .tree-node-active.tree-node-focused > * > .node-wrapper > .node-content-wrapper > .page-node-wrapper {
    background: #ddd !important;

    .commands {
      background: #ddd !important;
    }
  }

  .page-node-wrapper {
    padding: 1px;
    border: 1px solid transparent;
    border-radius: 2px;

    &:not(.image-manager-page) {
      &.with-page {
        padding-left: 28px;
        border-left: 0;

        &:hover {
          border-left: 0;
        }
      }
    }

    &.image-manager-page {
      padding-left: 4px;
    }

    &.home-page {
      padding-left: 26px;
    }

    &.category-page {
      padding-left: 28px;
    }
  }
}

