@import "../styles/colors";
@import "../styles/keyframes";

#dropzone.dropzone {
  position: relative;
  height: 100%;
  padding: 5px !important;
  width: 100% !important;
  border: 2px solid transparent;
  background: none !important;
  z-index: 0;

  &.dz-drag-hover {
    border: 2px dashed #999 !important;
  }
}

.dropzone {
  &.exclamation-icons-hidden {
    .exclamation-icon-wrapper {
      display: none !important;
    }
  }

  .dz-preview {
    z-index: 10 !important;
    background: none !important;
    position: relative;
    margin: 2px 10px 10px 0 !important;
    border: 2px solid transparent;
    min-height: initial !important;
    max-width: 100%;
    display: inline-block;
    user-select: none;
    box-shadow: 0 0 5px rgba(0 ,0 , 0, .5);

    &:hover {
      z-index: 11 !important;
    }

    &.dz-file-preview.dz-error {
      min-width: 150px;
    }

    &.selected,
    &.ui-selected {
      border-color: #e93f33;

      &:hover {
        border-color: #e93f33 !important;
      }
    }

    .dz-details {
      display: none !important;
    }

    .dots-container,
    .student-image-rating-bar,
    .student-image-overlay,
    .students-tab-image-overlay {
      user-select: none;
    }

    .dots-container {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      padding: 4px;

      .red-dot {
        display: block;
        width: 15px;
        height: 15px;
        z-index: 10;
        margin-right: 5px;
        background: #e93f33;
        border: 1px solid #333;
        cursor: pointer;
        border-radius: 50%;

        &:hover {
          z-index: 20;
        }
      }

      .exclamation-icon-wrapper {
        color: #333333;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 10;
        position: relative;

        &:hover {
          z-index: 105;
        }

        .exclamation-icon {
          background: yellow;
          border: 1px solid;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 600;
          padding-left: 1px;
          cursor: pointer;

          &:hover {
            + .tooltip-box {
              display: block;
            }
          }

          &.left-side {
            + .tooltip-box {
              transform: translate(-55%, 40%);

              .hint {
                flex-direction: row-reverse;

                .triangle {
                  border-left: 5px solid black;
                  border-right: unset;
                }
              }
            }
          }
        }
      }
    }
    
    .exclamation-icon-wrapper {
      .tooltip-box {
        transform: translate(55%, 40%);
      }
    }
    
    .educator-thumb-button {
      .tooltip-box {
        top: 0;
        transform: translate(55%, 1px);
      }
    }

    .exclamation-icon-wrapper,
    .educator-thumb-button {
      .tooltip-box {
        display: none;
        position: absolute;
        width: 150px;

        .hint {
          display: flex;
          align-items: flex-start;

          @extend %ease-in-animation;

          .triangle {
            width: 1px;
            height: 0;
            margin-top: 5px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid black;
          }

          .text-wrapper {
            background: $tooltip-background;
            border: 1px solid $primary-black;

            .text {
              padding: 6px;
              font-size: 10px;
              line-height: 16px;
              color: $primary-bright;
            }
          }
        }
      }
    }

    &:hover {
      .dz-image img {
        transform: none !important;
        filter: none !important;
      }
    }

    .dz-image {
      width: initial !important;
      height: auto !important;
      border-radius: 0 !important;

      &.gray-thumbnail {
        background-color: #a2a2a2;

        img {
          opacity: 0;
        }
      }

      img {
        height: 150px;
        cursor: pointer;
        display: inline-block;
        max-width: 100%;
        object-fit: contain;
      }

    }

    .dz-remove:not(.setup-image-remove) {
      display: none !important;
    }

    .dz-remove {
      position: absolute;
      font-size: 15px !important;
      background: rgba(0, 0, 0, 0.8);
      padding: 4px;
      border: 1px solid #333333 !important;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      line-height: 15px;
      top: 2px;
      right: 2px;
      z-index: 12;
      color: #e93f33;

      &:before {
        letter-spacing: 0;
      }

      &:hover {
        background: rgba(0, 0, 0, 1);
        border: 1px solid #636363 !important;
        text-decoration: unset !important;
      }
    }

    &.dz-error {

      .dz-remove {
        display: block !important;
      }
    }

    .options {
      opacity: 0;
      pointer-events: none;
      display: block;
      position: absolute;
      top: calc(100% + 2px);
      left: -2px;
      height: fit-content;
      z-index: 1025;
      background-color: rgba(0,0,0, 0.65);
      color: #fff;
      font-weight: normal;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: 0.2s all linear;

      span {
        cursor: pointer;
        display: inline-block;
        width: max-content;
        margin: 2px 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:hover {
      .options {
        pointer-events: all;
        opacity: 1;
      }
    }

    .dz-success-mark {
      display: none !important;
    }

    &.hidden-thumbnail {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

  }

  .sortable-placeholder {
    display: inline-block !important;
    margin: 5px 5px 0 5px !important;
    border: 2px dashed #999;
  }

  &.dz-started {

    .dz-message {
      display: flex;
    }
  }

  .dz-message {
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    color: #999;

    span {
      font-size: 16px;
      line-height: 1.5em;

      .upload-note {
        position: absolute;
        top: 63px;
        left: 36px;
        font-family: 'Lato', Arial, Sans-serif;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }
  }

  .dz-error-message {
    padding: 5px !important;
    text-align: center !important;
    left: 0 !important;
    width: 100% !important;

    &:after {
      left: 50% !important;
    }

    > span {
      font-size: 10px !important;
    }
  }

}
