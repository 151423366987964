// You can add global styles to this file, and also import other style files
@use "sass:math";

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;

  body {
    margin: 0;
  }
}

img,
svg {
  vertical-align: middle;
}

input:focus,
button:focus {
  outline: none !important;
}

@import "../node_modules/bootstrap/scss/bootstrap";
@import "styles/grab";
@import "styles/loader";
@import "styles/elements";
@import "styles/input-range";
@import "styles/classes";
@import "styles/form";
@import "styles/modal";
@import "styles/table";
@import "styles/sidebar-menu";
@import "styles/tree";
@import "styles/dnd";
@import "styles/color-picker";
@import "styles/scrollbar";
@import "styles/slider";
@import "styles/dropzone";
@import "styles/image-manager";
@import "styles/page-builder";
@import "styles/_menu-editor-toolbar";
@import "styles/_multi-select";
@import "styles/_google-analytics-charts";

select {
  overflow: auto;
}

:not(select) {
  &::-webkit-scrollbar-track
  {
    background-color: transparent;
  }

  &::-webkit-scrollbar
  {
    width: 8px;
    height: 12px;
    background-color: #000;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb
  {
    width: 5px;
    height: 5px;
    background-color: #999;
    border-radius: 0;
  }
}

%tab-hover {
  .nav-link {
    &:hover:not(.active):not(.disabled) {
      color: #fff;
    }
  }
}

.nav-tabs {
  border-bottom: none;
  font-size: 10px;
  text-transform: uppercase;

  .nav-item {
    margin: 0;

    .nav-link {
      color: #bdc3c8;
      border: none;
      border-radius: 0;
      line-height: 11px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 32px;
      padding: 0;

      &.active {
        color: #bdc3c8;
        background-color: #3f3e3e;
      }

      &:hover {
        color: #bdc3c8;
      }
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.detail-tabs {
  ul.nav-tabs {
    width: 100%;

    @extend %tab-hover;
  }
}

.control-tabs {
  ul.nav-tabs {
    @extend %tab-hover;
    height: 100%;
  }
}

// used in <tabset> component
.tab-content {
  height: 100%;
  overflow: hidden;

  .tab-pane {
    height: 100%;
  }
}

.details {
  .panel {
    .warning {
      margin-top: 8px;
    }
  }
}

// it's styles of close-icon for font-picker
.dropdown-wrapper {
  .dropdown-content {
    .close-btn {
      transform: translate(0%, -200%);
      right: 0;
      top: 0;
    }
  }
}

app-main input[type='file'] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.zsiq_theme1 {
  &.zsiq_floatmain {
    max-height: 40px !important;
  }

  .zsiq_flt_rel {
    width: 40px !important;
    height: 40px !important;
  }

  .siqico-chat {
    &:before {
      line-height: 40px !important;
      font-size: 18px !important;
      text-shadow: 0 0 1px #377300;
    }
  }
}
