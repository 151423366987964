
// Special styling for WebKit/Blink
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #666;
  border: 1px solid #666;
  cursor: pointer;
  
  // You need to specify a margin in Chrome, but in Firefox and IE it is automatic
}

// All the same stuff for Firefox
input[type=range]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #666;
  border: 1px solid #666;
  cursor: pointer;
}

// All the same stuff for IE
input[type=range]::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #666;
  border: 1px solid #666;
  cursor: pointer;
}

// Track
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #ccc;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #ccc;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #ccc;
}
input[type=range]:focus::-ms-fill-lower {
  background: #ccc;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ccc;
}
