@import "./mixins.scss";

.clearfix {
  @extend %clearfix;
}

.green {
  color: #ddfec8;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.text-center {
  text-align: center;
}

.resize-handle-e {
  cursor: e-resize;
}

.action {
  color: #777;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.hidden-offset {
  display: block !important;
  position: absolute !important;
  top: -1000000px !important;
  left: -1000000px !important;
}

.select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
}
