.table {
  > :not(caption) > * > * {
    padding: initial;
    color: initial;
    background-color: transparent;
    border-bottom-width: 0;
    box-shadow: none;
  }

  a {
    color: $primary-blue;

    &:hover {
      color: $primary-blue-dark;
    }
  }
}