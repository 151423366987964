@import "../styles/colors";
@import "../styles/mixins";


.form {
  width: 100%;
  height: 100%;
  padding: 20px;

  .alert {
    margin: 10px 0;
    padding: 10px;
    color: #080;
    background: tint(#080, 70%);
    border: 1px solid #080;

    a {
      color: shade(#080, 30%);
    }
  }

  form {
    width: 100%;
    max-width: 400px;
    margin: 120px auto 40px auto;
    @extend %clearfix;

    h2 {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    label {
      display: block;
      margin-bottom: 10px;
      color: #555;

      &.error {
        input {
          border-color: #800;
        }

        .help {
          color: red;
        }

        .upgrade-password-info {
          color: black;
          margin: 10px 0;
        }
      }

      input[type="text"],
      input[type="password"] {
        width: 100%;
      }

      > span {
        display: inline-block;
        margin-bottom: 5px;
      }
    }

    button {
      float: right;
      margin-left: 15px;
    }

    a {
      color: #333;
    }
  }
}
