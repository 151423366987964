
.color-picker {
  width: 241px !important;
  top: 30px !important;
  right: 0 !important;
  left: unset !important;

  .color-picker_ok {
    width: 100%;
    border-radius: 5px;
    font-weight: 700;
    background: #cccccc;
    font-size: 12px;

    &:hover {
      background: #e4e4e4;
    }
  }

  .arrow {
    display: none !important;
  }

  input {
    width: 100% !important;
  }
}
