
.scrollbar {
  height: 100% !important;
  position: relative;

  &.im-height {
    height: calc(100% - 42px) !important;
  }

  &.image-manager-height {
    height: calc(100% - 40px) !important;
    margin-left: 30px;
  }

  .ps {
    padding: 10px 30px 20px 20px;
    height: 100%;

    .ps-content {
      min-height: initial !important;
    }
  }

  .ps__scrollbar-x-rail,
  .ps__scrollbar-y-rail {
    z-index: 15;
  }
}

.details {

  .scrollbar {

    .ps {
      padding: 0 15px 15px 15px;
    }
  }
}

.custom-scrollbar::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #333333;
}

.custom-scrollbar::-webkit-scrollbar
{
  width: 8px;
  background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #989797;
}


.custom-scrollbar-white::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  background-color: #eeeeee;
}

.custom-scrollbar-white::-webkit-scrollbar
{
  width: 5px;
  background-color: #F5F5F5;
}

.custom-scrollbar-white::-webkit-scrollbar-thumb {
  background-color: #000000;
}
