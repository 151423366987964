$animation-time: .2s;

// ***** ***** ***** ***** *****
// Animations start
// ***** ***** ***** ***** *****

// opacity-animation start

@-webkit-keyframes opacity-animation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes opacity-animation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes opacity-animation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes opacity-animation {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

%ease-in-animation {
  -webkit-animation: opacity-animation $animation-time ease-in;
  -moz-animation: opacity-animation $animation-time ease-in;
  -o-animation: opacity-animation $animation-time ease-in;
  animation: opacity-animation $animation-time ease-in;
}

// opacity-animation end

//
//
//

// gradient-animation start

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

@-moz-keyframes gradient-animation {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

// gradient-animation end

@keyframes pulse-recording {
  0% {
    box-shadow: 0 0 0 0 rgba(236, 57, 46, 0.7);
  }
  
  70% {
    box-shadow: 0 0 0 10px rgba(236, 57, 46, 0);
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

// ***** ***** ***** ***** *****
// Animations end
// ***** ***** ***** ***** *****
