@import './fonts';
@import './colors';

html, body {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 1px;
  background: $body;
  height: 100%;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

b, strong, .bold {
  font-weight: bold;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
textarea {
  padding: 10px;
  border: 1px solid #555;

  &:focus {

  }
}

select {
  padding: 0 3px;
}

input[type="range"] {
  -webkit-appearance: none; // Hides the slider so that custom slider can be made
  width: 100%; // Specific width is required for Firefox.
  background: transparent; // Otherwise white in Chrome

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    // Hides the slider so custom styles can be added
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &:focus {
    outline: none; // Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though.
  }

  // Special styling for WebKit/Blink
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -14px; // You need to specify a margin in Chrome, but in Firefox and IE it is automatic
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  // All the same stuff for Firefox
  &::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  // All the same stuff for IE
  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
}

button {
  padding: 5px 10px;
  border: 1px solid #555;
  background: #fff;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #ccc;
  }

  &:active {
    background: #333;
    color: #fff;
  }
}

app-main {
  height: 100%;
}

app-sidebar,
app-image-details,
app-designer-templates,
app-designer-pages,
app-designer-blocks,
app-bottom-bar,
sidebar {
  height: 100%;
}
