
.ui-sortable-helper,
.ui-draggable-dragging {
  list-style: none !important;
  width: auto !important;
  height: auto !important;
  z-index: 100000 !important;
  user-select: none;
  opacity: .5;

  img {
    display: block;
    max-width: 100%;
    width: auto !important;
    height: 100px !important;
  }

  .options {
    display: none !important;
  }

  .dz-details,
  .dz-progress,
  .dz-error-message,
  .dz-success-mark,
  .dz-error-mark,
  .dz-remove {
    display: none !important;
  }
}

.page-node,
.portfolio-item {

  .sortable-placeholder {
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100% !important;
    height: 100% !important;
    background: #ccc;
    outline: 2px dashed red !important;
    outline-offset: -2px;
    opacity: .5;

    .dz-details,
    .dz-progress,
    .dz-error-message,
    .dz-success-mark,
    .dz-error-mark,
    .dz-remove {
      display: none !important;
    }

    img {
      display: none !important;
    }
  }
}
