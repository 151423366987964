// Page Loader

.loader {
  position: relative;
  width: 100%;
  min-height: 60px;
  pointer-events: none !important;
  z-index: 100000;
  $size : 50px;
  color: black;

  &:after {
    display: block;
    content: '\00a0';
    width: $size;
    height: $size;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: math.div(-$size, 2) 0 0 math.div(-$size, 2);
    z-index: 100001;
    font-size: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    animation: spinner 700ms infinite linear;
    will-change: transform;
    pointer-events: none !important;
  }

  &.white:after {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-right: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }

  $colors: #ccc #fff #000;

  @each $color in $colors {
    $c: str-slice(#{$color}, 2);
    &.color-#{$c} {
      background: $color;
    }
  }

  @for $i from 1 through 20 {
    $size: $i * 5px;

    &.size-#{$size}:after {

      width: $size !important;
      height: $size !important;
      margin: math.div(-$size, 2) 0 0 math.div(-$size, 2) !important;
    }
  }
}

#page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  background: #ccc;
  pointer-events: none !important;
}
