

// @import "./mixins.scss";
app-modal {
  .modal {
    .modal-body {
      h1 {
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 15px;
      }

      .modal-content-wrapper {
        margin: 20px;
        clear: both;
        overflow: hidden;

        .question-text {
          font-size: 0.9em;
        }

        .actions {
          float: right;
          margin-top: 20px;

          button {
            margin-left: 10px;
            min-width: 70px;
          }
        }
      }
    }
  }
}
// app-modal {
//   .modal {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     display: block;
//     width: auto;
//     height: auto;
//     color: $modal-color;
//     z-index: 2;
//     padding: 20px;

//     .modal-body {
//       padding: 10px;
//       overflow: visible;
//       width: 500px;
//       max-width: 500px;
//       z-index: 9999;

//       h1 {
//         font-size: 20px;
//         text-transform: uppercase;
//         color: #fff;
//         margin-bottom: 20px;
//       }

//       p {
//         margin-bottom: 15px;
//       }

//       .modal-content-wrapper {
//         margin: 20px;
//         clear: both;
//         overflow: hidden;

//         .question-text {
//           font-size: 0.9em;
//         }

//         .actions {
//           float: right;
//           margin-top: 20px;

//           button {
//             margin-left: 10px;
//             min-width: 70px;
//           }
//         }
//       }
//     }
//   }

//   // make the modal smaller
//   &.sm {
//     .modal-body {
//       width: 420px;
//       max-width: 420px;
//     }
//   }

//   // make the modal larger
//   &.lg {
//     .modal-body {
//       width: 900px;
//       max-width: 900px;
//     }
//   }

//   &.w-40 {
//     .modal-header,
//     .modal-body {
//       width: 40%;
//     }
//   }

//   &.w-80 {
//     .modal-header,
//     .modal-body {
//       width: 80%;
//     }
//   }

//   &.h-80 {
//     .modal-body {
//       height: 80%;
//     }
//   }

//   &.h-85 {
//     .modal-body {
//       height: 85%;
//     }
//   }

//   &.mw-95 {
//     .modal-header,
//     .modal-body {
//       min-width: 95%;
//     }
//   }

//   &.h-95 {
//     .modal-body {
//       height: 95%;
//     }
//   }

//   &.w-100 {
//     .modal-header,
//     .modal-body {
//       width: 100%;
//     }
//   }

//   &.h-100 {
//     .modal-body {
//       height: 100%;
//     }
//   }

//   &.grey {
//     .modal-body {
//       background-color: #ccc;
//     }
//   }

//   &.no-padding {
//     .modal,
//     .modal-body {
//       padding: 0 !important;
//     }
//   }

//   &.scroll {
//     .modal-body {
//       overflow: auto;
//     }
//   }
// }
