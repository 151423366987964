@import "./mixins.scss";

.image-manager {

  ul {
    @extend %clearfix;
    margin-bottom: 20px;

    li {
      position: relative;
      float: left;
      margin: 5px;
      border: 2px solid #ccc;
      box-shadow: 0 0 5px rgba(0 ,0 , 0, .5);

      &.selected {
        border-color: #e93f33;

        &:hover {
          border-color: #e93f33 !important;
        }
      }

      &.sortable-placeholder {
        display: inline-block !important;
        margin: 5px 5px 0 5px !important;
        border: 2px dashed #999 !important;
        box-shadow: none !important;
      }

      img {
        display: block;
        width: auto;
        height: 150px;
        cursor: pointer;
      }
    }
  }
}

.dragging-image-ghost {
  .dots-container,
  .student-image-rating-bar,
  .student-image-overlay,
  .students-tab-image-overlay {
    display: none !important;
  }
}
