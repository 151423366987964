@import "./mixins.scss";

.page-builder {
  width: 100%;
  height: auto;
  position: relative;

  // clear floated elements to prevent layout breaking
  @extend %clearfix;

  &.drag-over {
    outline: 2px dashed #ff8383;
    outline-offset: -10px;
  }

  > img {
    width: 100% !important;
  }

  iframe {
    display: block !important;
    background: #fff;
    width: 100% !important;
    height: 100%;
  }

  // set 'grab' and 'grabbing' cursors when dragging
  .commands {

    .sort-handle {
      cursor: grab;

      &.grabbing {
        cursor: grabbing;
      }
    }
  }
}
