.select-item {
  color: #ccc;
  //background: red;
}

.dropdown-menu {
  background: #585858;
  transform: translate(-100px);
}

//.select-button {
//  color: #ccc;
//  background: red;
//}
//
//.select-container {
//  color: #ccc;
//  background: red;
//}
