$primary-bright: #fff;
$primary-light: #eee;
$primary-3: #ececec;
$primary-medium-light: #ddd;
$primary: #ccc;
$primary-5: #d8d8d8;
$primary-6: #d1d1d1;
$primary-silver: #bec3c7;
$primary-7: #b9b9b9;
$primary-8: #bbbbbb;
$primary-hover-grey: #c3c3c3;
$primary-hover-dark-grey: #b7b7b7;
$primary-border-grey: #c3c3c3;
$primary-border-grey-darker: #9e9e9e;
$primary-border-grey-hover: #9f9f9f;
$primary-15: #9e9e9e;
$primary-grey-bright: #999;
$primary-17: #777777;
$primary-dark-middle: #777;
$primary-20: #656565;
$primary-23: #5a5a5a;
$primary-dark: #555;
$primary-grey: #3f3e3e;
$primary-40: #333333;
$primary-grey-dark: #212529;
$body: #312f2f;
$text-dark-background: rgba(0, 0, 0, 0.4);
$primary-dark-background: rgba(0, 0, 0, 0.25);
$primary-middle-background: rgba(0, 0, 0, 0.5);
$primary-darkest-background: rgba(0, 0, 0, 0.75);
$primary-middle-black-background: rgba(0, 0, 0, 0.95);
$primary-90: #111;
$primary-black: #000;
$primary-dark-transparent: rgba(150, 150, 150, .75);
$primary-dark-transparent-2: rgba(255, 255, 255, 0.1);
$primary-yellow-20: #f7f3c9;
$primary-yellow: #f6f192;
$primary-yellow-dark: #f7d618;
$primary-yellow-dark-text: #eac600;
$primary-orange-1: #EA9000;
$primary-orange-2: #fd6700;
$primary-orange-3: #ff4d00;
$primary-green: #ddfec6;
$primary-green-extrabright: #fdfffc;
$primary-green-light: #f5fff0;
$primary-green-30: #8fc063;
$primary-green-border: #8fc063;
$primary-green-bright: #4e9c0a;
$primary-green-dark: #3c7808;
$primary-green-darkest: #2a5406;
$primary-green-dark-transparent: rgba(80, 220, 45, .25);
$primary-red-1: #ffe3e3;
$primary-red-2: #dc544a;
$primary-red-3: #ffc0c0;
$primary-red-4: #901a16;
$primary-red-5: #7e1713;
$primary-red-6: rgba(255, 0, 0, 0.2);
$primary-red-7: #ffa195;
$primary-red-border: #ffa195;
$primary-red: #ec392e;
$primary-red-dark: #ad0b01;
$primary-red-extrabright: #fffafa;
$primary-blue-combobright: #fafdff;
$primary-blue-extrabright: rgb(231, 243, 251);
$primary-blue-bright: #2080cd;
$primary-blue: #1c6bad;
$primary-blue-dark: #164d7a;

$publish-button-color: #d4342d;
$publish-button-hover-color: #781913;
$publish-button-active-color: #ddfec6;
$publish-button-active-border-color: #f9f9f9;
$publish-button-active-hover-color: #56d058;

$warning-header: #f6f192;

$background: #fcfcfc;
$background-grey: #b5b5b5;
$page-editor-background-color: #3f3e3e;
$page-editor-border-color: #312f2f;

$tooltip-background: #312f2f;
$tooltip-color-dark: #333333;

$button-green: #f6f192;

$modal-title-background: #312f2f;
$modal-title-success-background: #c6ef92;
$modal-title-error-background: #f4958b;
$modal-title-neutral-background: #cccccc;
$modal-title-warning-background: #aaaaaa;
$modal-body-background: #fdfdfd;
$modal-color: #333;

$modal-neutral-button: #989898;
$modal-neutral-button-hover: #666666;
$modal-neutral-button-color: #fff;
$modal-neutral-button-hover-color: #fff;

$modal-red-button: #c71a11;
$modal-red-button-hover: #ad0b01;
$modal-red-button-color: #fff;
$modal-red-button-hover-color: #fff;

$modal-green-button: #509e08;
$modal-green-button-hover: #3c7808;
$modal-green-button-color: #fff;
$modal-green-button-hover-color: #fff;

$exclamation-color: #333333;

$popup-input-background: #eaeaea;

$thumbnail-hover: #ff0000;

$customizer-option-name: $primary;

$blue-background: #ecf8ff;
$blue-border: #aad3e6;

$table-header-background-bright: #eeeeee;
$table-header-background: #ababab;
$table-odd-row-background-bright: #f8f8f8;
$table-odd-row-background: #c3c3c3;

$setup-button-border-color: #333333;

$tour-background: rgba(0, 0, 0, 0.80);
